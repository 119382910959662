import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VibrationsComponent } from './pages/vibrations/vibrations.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'main',
    loadChildren: () =>
      import('./pages/main/main.module').then((m) => m.MainModule),
  },
  {
    path: 'vibrations',
    loadChildren: () =>
      import('./pages/vibrations/vibrations.component').then(
        (c) => c.VibrationsComponent
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
