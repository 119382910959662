import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { projectData } from 'src/app/pages/main/elements/types/project-data.type';
import { ConvertService } from '../convert/convert.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private abortController = new AbortController();
  private rootRoute = environment.rootRoute;
  private frontendRoute = environment.frontendRoute;

  cancelRequests() {
    this.abortController.abort(); 
    this.abortController = new AbortController(); 
  }

  userData = { username: "", scenario: "", well: "", project: "", client: "" };

  istest = false;

  testprod: any = "test";

  constructor(public http: HttpClient, private convert: ConvertService) { }

  getUser() {
    return new Promise((resolve) => {
      this.userData.username = localStorage.getItem("usernameDrillOpt")?.toLowerCase() || "";
      this.userData.scenario = localStorage.getItem("scenarioSelectedDrillOpt")?.toLowerCase() || "";
      this.userData.well = localStorage.getItem("wellSelectedDrillOpt")?.toLowerCase() || "";
      this.userData.project = localStorage.getItem("projectSelectedDrillOpt")?.toLowerCase() || "";
      this.userData.client = localStorage.getItem("clientSelectedDrillOpt")?.toLowerCase() || "";
      resolve(this.userData);
    });
  }

  setUser(username: any) {
    return new Promise((resolve) => {
      username = (username.substring(0, username.indexOf("@")).replace("-", "_").replace(".", "_").replace("@", "_")).toLowerCase();
      localStorage.setItem("usernameDrillOpt", username);
      this.userData.username = username;
      resolve(this.userData);
    });
  }

  getApiData(wellname: any, username: any, route: any, rows: any, onlyusername: boolean, lastrow: any, operator: any, wellactual: any, project: any) {
    return new Promise((resolve, reject) => {
      let headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': environment.apiTokens.default
      }
      let requestOptions = {
        headers: new HttpHeaders(headerDict),
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + route + "/" + username + "/" + wellname;
      if (onlyusername) {
        apiroute = this.rootRoute + route + "/" + username;
      }
      if (route.includes("ropmsethresholds")) {
        apiroute = this.rootRoute + route + "/" + operator + "/" + project + "/" + wellactual + "/" + username + "/" + wellname;
        headerDict['x-api-key'] = environment.apiTokens.ropmsethresholds;
        requestOptions = {
          headers: new HttpHeaders(headerDict),
          signal: this.abortController.signal
        };
      }
      if (route.includes("streamingdata")) {
        apiroute = this.rootRoute + route + "/" + operator + "/" + project + "/" + wellactual + "/" + username + "/" + wellname;
        headerDict['x-api-key'] = environment.apiTokens.streamingdata;
        requestOptions = {
          headers: new HttpHeaders(headerDict),
          signal: this.abortController.signal
        };
      }
      if (route.includes("startdepth") || route.includes("ropmsegraph")) {
        apiroute = this.rootRoute + route + "/" + operator + "/" + project + "/" + wellactual + "/" + username + "/" + wellname;
        headerDict['x-api-key'] = environment.apiTokens.ropmsegraph;
        requestOptions = {
          headers: new HttpHeaders(headerDict),
          signal: this.abortController.signal
        };
      }
      if (route.includes('rigactivity')) {
        apiroute = this.rootRoute + route + "/" + operator + "/" + project + "/" + wellactual + "/" + username + "/" + wellname;
        headerDict['x-api-key'] = environment.apiTokens.rigactivity;
        requestOptions = {
          headers: new HttpHeaders(headerDict),
          signal: this.abortController.signal
        };
      }
      if (route.includes("streamingscroll")) {
        apiroute = this.rootRoute + route + "/" + operator + "/" + project + "/" + wellactual + "/" + username + "/" + wellname;
        headerDict['x-api-key'] = environment.apiTokens.streamingdata;
        requestOptions = {
          headers: new HttpHeaders(headerDict),
          signal: this.abortController.signal
        };
      }
      if (route.includes("colors")) {
        apiroute = this.rootRoute + route + "/" + operator + "/" + project + "/" + wellactual + "/" + username + "/" + wellname;
        headerDict['x-api-key'] = environment.apiTokens.colors;
        requestOptions = {
          headers: new HttpHeaders(headerDict),
          signal: this.abortController.signal
        };
      }
      if (rows && rows >= 0) {
        apiroute += "/" + rows.toString();
      }
      if (lastrow && lastrow >= 0) {
        apiroute += "/" + lastrow.toString();
      }
      if (route.includes("schedulesgeneration")) {
        apiroute = `${this.rootRoute}${route}/${username}/${wellname}`

        headerDict['x-api-key'] = environment.apiTokens.schedulesgeneration;

        const newRequestOptions = {
          headers: new HttpHeaders(headerDict),
        };

        this.http.post(apiroute, '', newRequestOptions)
          .subscribe((response: any) => {
            let res = JSON.stringify(response);
            let responses: any = JSON.parse(res);
            if (responses.hasOwnProperty("data")) {
              resolve(this.convert.convertUnits(responses.data, route));
            } else {
              resolve(this.convert.convertUnits(responses, route));
            }
          }, error => {
            reject(error);
          });
        return;
      }
      if (this.istest) {
        apiroute = "assets/json/" + route.replace(".", "_").replace("/", "_") + ".json";
      }
      this.http.get(apiroute, requestOptions)
        .subscribe((response: any) => {
          let res = JSON.stringify(response);
          let responses: any = JSON.parse(res);
          if (responses.hasOwnProperty("data")) {
            resolve(this.convert.convertUnits(responses.data, route));
          } else {
            resolve(this.convert.convertUnits(responses, route));
          }
        }, error => {
          reject(error);
        });
    });
  }


  importApiDataFile(fileToUpload: File, route: any, wellname: any, username: any): any {
    return new Promise((resolve, reject) => {
      let apiroute = this.rootRoute + route + "/" + username + "/" + wellname;
      let formData: FormData = new FormData();
      formData.append('upload', fileToUpload);
      const headerDict = {
        'x-api-key': environment.apiTokens.default,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
        signal: this.abortController.signal
      };
      // const requestOptions = { headers };
      this.http.post(apiroute, formData, requestOptions)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  postApiData(route: any, wellname: any, username: any, data: any): any {
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': environment.apiTokens.default,
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + route + "/" + username + "/" + wellname;
      this.http.post(apiroute, data, requestOptions)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  putApiData(wellname: any, username: any, route: any, data: any) {
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': environment.apiTokens.scenarios
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + route + "/" + wellname + "/" + username;
      this.http.put(apiroute, data, requestOptions)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  // getIridianRoute = "https://apigateway2.enovate-upstream.com/drillbismf-"+this.testprod+"/api";
  // eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjU3MjczMTc1MjkiLCJpYXQiOjE3MTM5MTkzNjEsImV4cCI6MTcxNTEyODk2MX0.aTmlaIScahLZKRqc9tyGXjzAj9k44yvOv57DHscC36M
  // getIridianRoute = "https://apigateway2.enovate.app/frontend-accesscontrol-test/api";

  // getOperator(uid: any){
  //   return new Promise((resolve, reject) => {
  //     const headers = new HttpHeaders();
  //     headers.append('Access-Control-Allow-Origin', '*');
  //     headers.append('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, PUT');
  //     headers.append('Accept', 'application/json');
  //     headers.append('content-type', 'application/json');
  //     const requestOptions = { headers };
  //     this.http.get(this.getIridianRoute + "/get-usuario?uid="+uid, requestOptions)
  //       .subscribe((response: any) => {
  //         resolve(response);
  //       }, error => {
  //         reject(error);
  //       });
  //   });
  // }

  getOperator(userData: any) {
    return new Promise((resolve, reject) => {
      const dataLogin: any = JSON.parse(localStorage.getItem("dataLogin") || "");
      let token: any = dataLogin.token;
      const headers = {
        "x-access-token": `${token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }

      const requestOptions = { headers, signal: this.abortController.signal };
      this.http.get(this.frontendRoute + "/Username" + "/" + dataLogin.table + "/" + userData, requestOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  getScenarios(username: any) {
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': environment.apiTokens.scenarios
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + "drilloptim-scenarios-" + this.testprod + "/scenarioslist/" + username;
      this.http.get(apiroute, requestOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  getScenario(username: any, scenario: any) {
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': environment.apiTokens.scenarios
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + "drilloptim-scenarios-" + this.testprod + "/scenario/" + username + "/" + scenario;
      this.http.get(apiroute, requestOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  createUpdateScenario(username: any, scenario: any, data: any, create: boolean = true) {
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': environment.apiTokens.scenarios
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + "drilloptim-scenarios-" + this.testprod + "/scenario/" + username + "/" + scenario;
      if (create) {
        this.http.post(apiroute, data, requestOptions)
          .subscribe((response: any) => {
            resolve(response);
          }, error => {
            reject(error);
          });
      } else {
        this.http.put(apiroute, data, requestOptions)
          .subscribe((response: any) => {
            resolve(response);
          }, error => {
            reject(error);
          });
      }
    });
  }

  deleteScenario(username: any, scenario: any) {
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': environment.apiTokens.scenarios
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + "drilloptim-scenarios-" + this.testprod + "/scenario/" + username + "/" + scenario;
      this.http.delete(apiroute, requestOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  rootStream = environment.rootStream;
  private getWellsRoute: any = environment.apiRoutes.getWells;

  getWellsFromApi(operator: any) {
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': environment.apiTokens.wells
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
        signal: this.abortController.signal
      };
      this.http.get(this.getWellsRoute + "/" + operator, requestOptions)
        .subscribe(response => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  async getProjectData() {
    return new Promise<projectData[]>((resolve, reject) => {
      const dataLogin: any = JSON.parse(localStorage.getItem("dataLogin") || "");
      let token: any = dataLogin.token;
      const headers = {
        "x-access-token": `${token}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
      const requestOptions = { headers, signal: this.abortController.signal };
      this.http.get(this.frontendRoute + "/Proyects", requestOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

  getCompleteRealtimeData(route: any, user: any) {
    return new Promise((resolve, reject) => {
      const headerDict = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'x-api-key': environment.apiTokens.streamingdata
      }
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
        signal: this.abortController.signal
      };
      let apiroute = this.rootRoute + route + "/" + user.client + "/" + user.project + "/" + user.well + "/" + user.username + "/" + user.scenario;
      this.http.get(apiroute, requestOptions)
        .subscribe((response: any) => {
          resolve(response);
        }, error => {
          reject(error);
        });
    });
  }

}
