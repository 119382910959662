import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {
  @Input() message: string = '';
  @Input() show: boolean = false;
  @Input() isCancel: boolean = false;
  @Input() isOk: boolean = false;
  @Output() confirm = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  onConfirm(): void {
    this.confirm.emit();
    this.show = false; 
  }

  onCancel(): void {
    this.cancel.emit();
    this.show = false; 
  }
}