export const environment = {
  production: false,
  rootRoute: "https://apigateway2.enovate.app/",
  frontendRoute: "https://apigateway2.enovate.app/frontend-accesscontrol-test/api",
  rootStream: "https://apigateway2.enovate.app/streams-api",
  apiTokens: {
    default: "b268111a8e91ffdf5a50e7241860e7480e97e5995cc2e8185141b0cf162df613",
    ropmsethresholds: "9b102f5694718362eb88fa0ebf15b7d4d986dcf0f07bee13b91331e3f0f148c2",
    streamingdata: "0002f6ecd6c24b3eaf36e1f5a3605e579acd3410ffd5cbad06ae75c1056bdbd7",
    startdepth: "4b4b5bb1ecd376f9112a04603c5572cb2ed2b0225b62cabe568d077c8f518583",
    ropmsegraph: "4b4b5bb1ecd376f9112a04603c5572cb2ed2b0225b62cabe568d077c8f518583",
    rigactivity: "e5464f0d015a445da869e462350ceb12dd1af032615bedd0ec910600e610de70",
    streamingscroll: "0002f6ecd6c24b3eaf36e1f5a3605e579acd3410ffd5cbad06ae75c1056bdbd7",
    colors: "febcf6d9185658ed78762f8ead66343cca22f2ee98d797af48620f014be02963",
    schedulesgeneration: "f2f3153b761e5643928bdd2e55f8e8879a052f2152f50a3bc88fdada8b5dad92",
    wells: "547d06b2dfd842b3062d201acfb348db15ba4e955b2011848fe164d8d28d423e",
    scenarios: "a5297f10472104281cb0fc19d37209e2bb7f5920635161b7582cd17cb7512a34",
    parameters: "19f3bec2c44d81799240263f4e4a79cdbad10cd7140f50b84ffdf9eb9c8e7183"
  },
  keyEncrypt: ",%0<x98r?\\u8s_AjIjt2gZLFc71E0zUh",
  apiRoutes: {
    getWells: "https://apigateway2.enovate.app/streams-api/selectoperatorwells",
    getProyects: "https://apigateway2.enovate.app/frontend-accesscontrol-test/api/Proyects",
    getOperator: "https://apigateway2.enovate.app/frontend-accesscontrol-test/api/Username"
  }
};

